// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/home/vsts/work/1/s/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-experiments-gummy-bunnies-tsx": () => import("/home/vsts/work/1/s/src/pages/experiments/gummy-bunnies.tsx" /* webpackChunkName: "component---src-pages-experiments-gummy-bunnies-tsx" */),
  "component---src-pages-experiments-tunnel-tsx": () => import("/home/vsts/work/1/s/src/pages/experiments/tunnel.tsx" /* webpackChunkName: "component---src-pages-experiments-tunnel-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/vsts/work/1/s/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/vsts/work/1/s/.cache/data.json")

