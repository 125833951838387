import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',

    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#222222',
    },
  },
  typography: {
    fontFamily: [
      '"Exo 2"',
      'sans-serif',
    ]
  },
});
/*
  palette: {
    type: 'dark',
  },
 */

export default theme;
